@import 'colors';
@import 'variables';
@import 'fonts';

html,
body {
  height: 100%;
}

body {
  font-family: $font-primary;
  margin: 0;
  font-size: 16px;
  background: #f5f8fb;
}

* {
  box-sizing: border-box;
}
