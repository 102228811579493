@font-face {
  font-family: 'Roboto';
  src: url('~assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: $font-weight-thin;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('~assets/fonts/Roboto-Light.ttf') format('truetype');
    font-weight: $font-weight-light;
    font-style: normal;
    font-display: swap;
  }

@font-face {
  font-family: 'Roboto';
  src: url('~assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: $font-weight-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('~assets/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: $font-weight-medium;
    font-style: normal;
    font-display: swap;
  }

@font-face {
  font-family: 'Roboto';
  src: url('~assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: $font-weight-bold;
  font-style: normal;
  font-display: swap;
}
