@import "colors";
@import "variables";

.heading-1 {
  font-size: 36px;
  font-weight: $font-weight-medium;

  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.heading-2 {
  font-size: 32px;
  font-weight: $font-weight-medium;

  @media (max-width: 768px) {
    font-size: 28px;
  }
}

.heading-3 {
  font-size: 16px;
  font-weight: $font-weight-bold;
  color: $gray-9;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.heading-4 {
  font-size: 22px;
  font-weight: $font-weight-medium;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.heading-5 {
  font-size: 14px;
  font-weight: $font-weight-medium;
  color: $gray-9;
}

.heading-6 {
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $gray-8;
}

.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  margin: 0;
}
