@font-face {
  font-family: "Roboto";
  src: url("~assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
html,
body {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  font-size: 16px;
  background: #f5f8fb;
}

* {
  box-sizing: border-box;
}

.heading-1 {
  font-size: 36px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .heading-1 {
    font-size: 30px;
  }
}

.heading-2 {
  font-size: 32px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .heading-2 {
    font-size: 28px;
  }
}

.heading-3 {
  font-size: 16px;
  font-weight: 700;
  color: rgb(38, 38, 38);
}
@media (max-width: 768px) {
  .heading-3 {
    font-size: 14px;
  }
}

.heading-4 {
  font-size: 22px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .heading-4 {
    font-size: 20px;
  }
}

.heading-5 {
  font-size: 14px;
  font-weight: 500;
  color: rgb(38, 38, 38);
}

.heading-6 {
  font-size: 12px;
  font-weight: 500;
  color: rgb(89, 89, 89);
}

.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  margin: 0;
}

.field {
  display: block;
  width: 100%;
}

.ant-form-item-label {
  padding: 0 !important;
}

.ant-input.ng-invalid.ng-touched {
  border: 1px solid #ff4d4f;
}

.ant-table-cell.warning {
  background: rgb(255, 241, 240);
}