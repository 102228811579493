@import "colors";

.field {
  display: block;
  width: 100%;
}

.ant-form-item-label {
  padding: 0 !important;
}

.ant-input {
  &.ng-invalid.ng-touched {
    border: 1px solid $error;
  }
}
